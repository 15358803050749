<template>
  <div class="filters">
    <b-form ref="form" @submit.prevent="submitSearch()">
      <b-row>
        <b-col v-if="hasAppliedFilters" cols="1">
          <b-button block type="button" variant="bordered" @click="resetForm()">
            <b-img :src="require('@/assets/img/icons/back-blue.svg')" alt="Back" />
          </b-button>
        </b-col>
        <b-col cols="3">
          <div class="select-wrapper">
            <b-form-select v-model="filters.project_id">
              <option :value="null">{{ $t('general.allProjects') }}</option>
              <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.name }}</option>
            </b-form-select>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="select-wrapper">
            <b-form-select v-model="filters.category_id">
              <option :value="null">{{ $t('general.allCategories') }}</option>
              <option v-for="categories in categories" :key="categories.id" :value="categories.id">{{ categories.name }}</option>
            </b-form-select>
          </div>
        </b-col>
        <b-col :cols="hasAppliedFilters ? 5 : 6">
          <b-input-group class="search">
            <b-form-input :placeholder="$t('forms.placeholders.search')" v-model="filters.search"></b-form-input>
            <b-input-group-append>
              <b-button block type="submit" variant="primary">
                <b-img :src="require('@/assets/img/icons/search-white.svg')" alt="Search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import bus from '@/api/bus';
import cleanObject from '@/utils/cleanObject';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      user: null,
      totalItems: null,
      filters: {
        project_id: this.$route.query.project_id || null,
        category_id: this.$route.query.category_id || null,
        search: this.$route.query.search || null,
        type: this.$route.query.type || null,
        full: this.$route.query.full || null,
      },
      hasSearched: false,
    };
  },
  computed: {
    hasAppliedFilters() {
      return this.hasSearched && Object.values(this.filters).some(filter => filter !== null);
    },
    ...mapGetters({
      categories: 'common/categories',
      projects: 'common/projects',
    }),
  },
  created() {
    bus.$on('updateFilters', () => {
      this.filters = {
        project_id: this.$route.query.project_id || null,
        category_id: this.$route.query.category_id || null,
        search: this.$route.query.search || null,
        type: this.$route.query.type || null,
        full: this.$route.query.full || null,
      };
    });
  },
  methods: {
    submitSearch() {
      const query = cleanObject(this.filters);
      this.$router.push({ name: 'search', query }).catch(() => {});
      this.hasSearched = true;
    },

    resetForm() {
      this.filters = {
        project_id: null,
        category_id: null,
        search: null,
      };

      this.submitSearch();
      this.hasSearched = false;
    },
  },
};
</script>
